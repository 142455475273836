<template>
  <vx-card>
    <!--------------------------------------------------------------------->
    <!-- UPLOAD IMAGE -->
    <!--------------------------------------------------------------------->

    <div
      class="grid-layout-container alignment-block"
      style="vertical-align:text-top ;width:100%;"
    >
      <vs-row vs-w="12">
        <vue-dropzone
          id="dropimage"
          ref="dropzone"
          :options="dropzoneOptions"
          :useCustomSlot="true"
          @vdropzone-complete="uploadComplete"
          @vdropzone-error="uploadError"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">
              Drag and drop to upload content!
            </h3>
            <div class="subtitle">
              ...or click to select a file from your computer
            </div>
          </div>
        </vue-dropzone>
        <!-- <button @click="removeAllFiles()">Remove All Files</button> -->
      </vs-row>
      <vs-row vs-w="12" style="margin-top:30px;">
        <div
          v-for="(item, index) in this.images"
          :key="index"
          class="grid-layout-container alignment-block"
          style="vertical-align:text-top ;width:100%;"
        >
          <vs-row vs-w="12">
            <div @click="removeItemImage(item.imageid, index)">
              <i
                class="vs-icon notranslate icon-scale vs-button--icon material-icons null"
                style="order: 0;text-align:left;  font-size:40px; color:red; cursor:pointer;"
                >cancel</i
              >
            </div>
          </vs-row>
          <vs-row vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="12"
              vs-sm="12"
              vs-xs="12"
              class="imgclass"
            >
              <img :src="item.itemimage" style="width:700px;" />
            </vs-col>
          </vs-row>
        </div>
      </vs-row>
    </div>
    <!-- UPLOAD IMAGE -->
  </vx-card>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  props: {
    productgroupid: String
  },
  data() {
    return {
      dropzoneOptions: {},
      images: [],
      uploadurl: ""
    };
  },
  created() {
    this.pid = this.productgroupid;
    console.log('this.pid >>> ',this.pid);

    var tk = localStorage.getItem("token");
    var tkpaser = JSON.parse(tk);
    // $#@
    this.uploadurl =
      this.$store.state.apiURL + "/api/review-image-upload/" + this.pid;
    this.dropzoneOptions = {
      url: this.uploadurl,
      maxFilesize: 1, // MB
      headers: {
        Authorization: "bearer " + tkpaser
      }
      // maxFiles: 4,
      // chunking: true,
      // chunkSize: 500, // Bytes
      // thumbnailWidth: 150, // px
      // thumbnailHeight: 150,
      // addRemoveLinks: true
    };

  },
  async mounted() {
    await this.reloadImage();
  },
  methods: {
    async reloadImage() {
      // RESET ALL IMAGES
      this.images = [];

      console.log('this.pid >>> ',this.pid);

      // LOAD รูปทั้งหมด
      var imgres = null;
      try {
        imgres = await this.$http.get(
          this.$store.state.apiURL + "/api/reviewimage/" + this.pid,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        imgres = null;
      }

      if (imgres !== null) {
        {
          for (var i = 0; i < imgres.data.length; i++) {
            var p = await imgres.data[i].fileName;
            // var sp = p.split("src");

            // var path = @sp[1];
            var resp = {
              // itemimage: require("@/assets/images/itemimage/" + p)
              // itemimage: require("@/assets/images/itemimage/" + p),
              itemimage: this.$store.state.reviewStore + (await p),
              imageid: await imgres.data[i].reviewImageId,
              fileName: await p
            };

            // console.log("@@@ CREATE ITEM >>> IMAGE RESP >>>> ", resp);

            this.images.push(resp);

          }

        }
      } else {
        this.noticeError("โหลดรูปภาพไม่สำเร็จ");
      }
    },
    removeItemImage(imageid, indextr) {
      this.$http
        .delete(
          this.$store.state.apiURL + "/api/reviewimage/" + imageid,
          this.$store.state.auth.apiHeader
        )
        .then(response => {
          if (response.status === 200) {
            // console.log("@ Remove IMAGEs ", imageid);

            this.$vs.notify({
              title: "ลบเรียบร้อยแล้ว",
              position: "top-center",
              text: "ลบเรียบร้อยแล้ว",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
            this.images.splice(indextr, 1);
            // console.log("response.data ", response.data);
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "ลบไม่สำเร็จ",
            position: "top-center",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
    },

    uploadError(file, message){
      //  console.log("@@@@@@@@@@@ message >>>> ",message);
       var msg = message.substring(0,15);
       if(msg === "File is too big"){
           this.$swal({
              icon: "error",
              title: "ขนาดไฟล์ภาพมีขนาดเกิน 1 MB"
              // text: 'Something went wrong!',
            });
       }else{
           this.$swal({
              icon: "error",
              title: "เกินความผิดพลาดบางอย่างในการอัพโหลดภาพ"
              // text: 'Something went wrong!',
            });
       }
    },

    // TRY TO UPLOAD
    uploadComplete(file) {
      console.log(file);
      this.$refs.dropzone.removeAllFiles();
      setTimeout(() => {
        //your code to be executed after 1 second
        this.reloadImage();
      }, this.delayInMilliseconds);

      // location.reload(true);
    },
    removeImage: function() {
      this.userImage = "";
    },
    // TRY TO UPLOAD
    removeAllFiles() {
      this.$refs.dropzone.removeAllFiles();
    }
  },
  components: {
    vueDropzone: vue2Dropzone
  }
};
</script>

<style>
.clear {
  display: none;
}

.con-img-upload {
  min-height: 150px;
  margin-top: 20px;
  /* margin-top: 170px; */
}
.con-upload {
  width: 45%;
  position: absolute;
}

.con-input-upload {
  width: 95%;
  height: 120px;
  /* position: absolute; */
}

.con-img-upload .img-upload {
  margin: 0 !important;
}

.vue-barcode-element {
  width: 300px !important;
}

.dropzone-custom-content {
  /* position: absolute; */
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #00b782;
}

.dropzone.dz-clickable {
  width: 100%;
  border: 1px dashed #4c4c4c;
}

.subtitle {
  color: #314b5f;
}

.imgclass .aura-btn {
  text-align: center;
  margin-left: 20px;
  margin-top: 90px;
  visibility: hidden;
  position: absolute;
  width: 90px;
  height: 30px;
  border: #4c4c4c 1px solid;
  color: white;
  cursor: pointer;
  background-color: #e89999b5;
}

.imgclass .setmain-btn {
  text-align: center;
  margin-left: 20px;
  margin-top: 50px;
  visibility: hidden;
  position: absolute;
  width: 80px;
  height: 30px;
  border: #4c4c4c 1px solid;
  color: black;
  cursor: pointer;
  background-color: #ffffffb5;
}

.imgclass:hover .aura-btn {
  visibility: visible;
}

.imgclass:hover .setmain-btn {
  visibility: visible;
}

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>> */

.ck-file-dialog-button {
  display: none;
}

.menubar__button {
  height: 30px;
  padding: 5px 5px !important;
  width: 40px !important;
  background-color: white !important;
  border-radius: 7px;
  cursor: pointer;
  font-size: 11px;
  margin-right: 5px;
}

.editor__content {
  width: 100%;
  margin-top: 20px;
  min-height: 200px;
  border-radius: 7px;
  border: 1px solid #4c4c4c;
}
</style>
